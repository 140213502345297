import { $DOCUMENT, ACTIVE, isEscPressed } from './constants';

export const getDropdownItem = (children) => (`
  <button type="button" class="autocomplete__item js-autocomplete-item">
    ${children}
  </button>
`);

const dropdownMarkup = (`
  <div class="autocomplete__dropdown js-dropdown">
    <div class="autocomplete__scrollable-container js-dropdown-items">
    </div>
  </div>
`);

export function autocomplete_Init() {
  const $field = $('.js-autocomplete-input');
  const $autocomplete = $('.js-autocomplete');

  $autocomplete.append(dropdownMarkup);

  $field.on('focus', function() {
    const $t = $(this);
    const $currentAutocomplete = $t.parents('.js-autocomplete');

    dropdown_Show($currentAutocomplete);

    $DOCUMENT.on('click.dropdown', function(event) {
      const $dropdown = $('.js-dropdown');

      if ($(event.target).closest($field).length || $(event.target).closest($dropdown).length) {
        return false;
      }

      dropdown_Hide();
    });

    $DOCUMENT.on('keyup.autocomplete', (event) =>{
      if (isEscPressed(event)) {
        dropdown_Hide();
      }
    });

    $DOCUMENT.on('click.item', '.js-autocomplete-item', function() {
      const $currentItem = $(this);
      const $currentField = $($field, $t.parents('.js-autocomplete'));

      $currentField.val($('.js-autocomplete-value', $currentItem).text());

      dropdown_Hide();
    });
  });
}

function dropdown_Show($autocomplete) {
  $autocomplete.addClass(ACTIVE);
}

function dropdown_Hide() {
  const $autocomplete = $('.js-autocomplete');

  $autocomplete.removeClass(ACTIVE);

  $DOCUMENT.off('click.dropdown keyup.autocomplete');
}
