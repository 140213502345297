import { $DOCUMENT, SCROLL_DURATION, TOPLINE_HEIGHT } from '../constants';

import { overlay_Hide, overlay_Show } from '../overlay';
import { getDropdownItem } from '../autocomplete';

import scrollToAnchor from '../utils/scrollToAnchor';

const getAutocompleteItemsMarkup = (items) => items.map(({ text, time }) => getDropdownItem(`
  <span class="delivery-autocomplete__item">
    <span class="delivery-autocomplete__city js-autocomplete-value">${text}</span>
    <span class="delivery-autocomplete__time">${time}</span>
  </span>
`));


const getAutocompleteItems = ($container, value) => {
  let items = window.autocompleteConfig || defaultAutocompleteConfig;
  if (value)  {
    items = items.filter(({ text }) => text.toLowerCase().startsWith(value.toLowerCase()));
  }

  $container.empty().append(getAutocompleteItemsMarkup(items));
}

export function findCity_Init() {
  const $btn = $('.js-find-city-btn');

  $btn.on('click', (event) => scrollToAnchor(SCROLL_DURATION, TOPLINE_HEIGHT)(event));
}

export function deliveryAutocomplete_Init() {
  const $autocomplete = $('.js-delivery-autocomplete');
  const $itemsContainer = $('.js-dropdown-items', $autocomplete);
  const $field = $('.js-autocomplete-input', $autocomplete);
  const $deliveryInfo = $('.js-delivery-info');
  const $closeDeliveryInfoBtn = $('.js-delivery-message-close-btn');

  getAutocompleteItems($itemsContainer);

  $field.on('focus', () => {
    const value = $field.val();

    if (value.length !== 0) {
      getAutocompleteItems($itemsContainer, value);
    }
  });

  $DOCUMENT.on('click.deliveryInfo', '.js-delivery-autocomplete .js-autocomplete-item', () => overlay_Show($deliveryInfo));
    $DOCUMENT.on("click", ".js-autocomplete-item", function () {
        var title = $(this).find('.delivery-autocomplete__city').text();
        var time = $(this).find('.delivery-autocomplete__time').text();
        var text = $(this).find('.delivery-autocomplete__description').text();

        $('.delivery-info__title').text(title);
        $('.delivery-info__time').text(time);
        $('.delivery-info__text').text(text);
    });
  $closeDeliveryInfoBtn.on('click', () => overlay_Hide());

  $field.on('keyup', () => getAutocompleteItems($itemsContainer, $field.val()));
}
