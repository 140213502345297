import { SCROLL_DURATION } from '../constants';

import scrollToSelector from './scrollToSelector';

const scrollToAnchor = (duration = SCROLL_DURATION, offset = 0) => (event) => {
  const target = (event.target).getAttribute('href');
  event.preventDefault();
  scrollToSelector(target, duration, offset);
};

export default scrollToAnchor;
