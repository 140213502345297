const AUTOPLAY_TIMEOUT = 4000;

export const getArrowContent = (className, isRight) => {
  const classnames = `
    slider-arrow
    ${ isRight ? 'slider-arrow--right' : '' }
    ${ className ? `${className}__arrow` : '' }
  `;

  return (`
    <svg class="${classnames}">
      <use xlink:href="sprite/sprite.svg#ico_arrow" />
    </svg>
  `);
}

const commonSliderOptions = {
  loop: false,
  autoplay: true,
  autoplayTimeout: AUTOPLAY_TIMEOUT,
  autoplayHoverPause: true,
}

const mainPageMainGalleryOptions = {
  ...commonSliderOptions,
  items: 1,
  nav: false,
};

const newOffersGalleryOptions = {
  ...commonSliderOptions,
  nav: true,
  navText: [
    getArrowContent(''),
    getArrowContent('', true),
  ],
  responsive:{
    0: {
      items: 1.5,
      center: true,
      slideBy: 1,
    },
    425: {
      items: 2,
      center: true,
      slideBy: 1,
    },
    768: {
      items: 3,
      center: false,
      slideBy: 3,
    },
    1024: {
      items: 4,
      center: false,
      slideBy: 4,
    }
  }
};

const sliderOptions = {
  ...commonSliderOptions,
  nav: false,
  items: 1,
}

export {
  mainPageMainGalleryOptions,
  newOffersGalleryOptions,
  sliderOptions,
};
