import { $BODY, $DOCUMENT, ACTIVE, SHOWN, isEscPressed } from './constants';

export function overlay_Init() {
  const $overlay = $('.js-overlay');
  const $content = $('.js-overlay-content');

  $overlay.on('click', (event) => {
    if ($(event.target).closest($('> div', $content)).length) {
      return null;
    }

    overlay_Hide();
  });
}

export function overlay_Show($target) {
  const $overlay = $('.js-overlay');

  $overlay.addClass(ACTIVE);

  if ($target) {
    $target.addClass(SHOWN);
  }

  $BODY
    .css({ maxWidth:  $BODY.width()})
    .addClass('shown-overlay');

  $DOCUMENT.on('keyup.overlay', (event) =>{
    if (isEscPressed(event)) {
      overlay_Hide()
    }
  });
}

export function overlay_Hide() {
  const $overlay = $('.js-overlay');

  $(`.${SHOWN}`, $overlay).removeClass(SHOWN);

  $overlay.removeClass(ACTIVE);

  $BODY
    .removeAttr('style')
    .removeClass('shown-overlay');

  $DOCUMENT.off('keyup.overlay');
}
