/** SVG sprite **/
require ('../assets/img/sprite/ico_scissors.svg');
require ('../assets/img/sprite/ico_chat-bubble.svg');
require ('../assets/img/sprite/ico_arrow.svg');
require ('../assets/img/sprite/ico_whats-app.svg');
require ('../assets/img/sprite/ico_viber.svg');
require ('../assets/img/sprite/ico_telegram.svg');
require ('../assets/img/sprite/ico_skype.svg');
require ('../assets/img/sprite/ico_instagram.svg');
require ('../assets/img/sprite/ico_odnoklassniki.svg');
require ('../assets/img/sprite/ico_vk.svg');
require ('../assets/img/sprite/ico_facebook.svg');
require ('../assets/img/sprite/ico_twitter.svg');
require ('../assets/img/sprite/ico_nav-arrow.svg');
require ('../assets/img/sprite/ico_eye.svg');
require ('../assets/img/sprite/ico_cross.svg');
require ('../assets/img/sprite/ico_expand.svg');
require ('../assets/img/sprite/ico_collapse.svg');
require ('../assets/img/sprite/ico_heart.svg');
require ('../assets/img/sprite/ico_heart--empty.svg');
require ('../assets/img/sprite/ico_path.svg');
