/** Styles **/
import './styles/styles.less';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'fotorama/fotorama.css';

/** JS **/
import './js';
import './js/icons';
import 'owl.carousel';

require('fotorama/fotorama');
