import { scrollToCoordinate } from './utils/scrollToCoordinate';
import { $WINDOW, M_TABLET, TOPLINE_HEIGHT } from './constants';
import { overlay_Show } from './overlay';

export function topline_Init() {
  const $logoContainer = $('.js-logo-container');

  $(window).scroll(function() {
    const scrollTop = $(window).scrollTop();

    scrollTop > 0
      ? $logoContainer.addClass('logo--small')
      : $logoContainer.removeClass('logo--small');
  });
}

export function logo_Init() {
  const $logoBtn = $('.js-logo');

  $logoBtn.on('click', () => scrollToCoordinate(0));
}

export function mobileMenu_Init() {
  const $btn = $('.js-mobile-menu-btn');
  const $menu = $('.js-mobile-menu');

  $btn.on('click', () => overlay_Show($menu));
}

export function contacts_Init() {
  const $btn = $('.js-contacts-btn');
  const $contacts = $('.js-contacts');
  const $firstContactBtn = $($btn[0]);

  $btn.on('click', function() {
    if ($WINDOW.width() >= M_TABLET) {
      $contacts.css(getContactsCoords($firstContactBtn));

      $WINDOW.on('resize', function() {
        if ($WINDOW.width() >= M_TABLET) {
          $contacts.css(getContactsCoords($firstContactBtn));
        } else {
          $(window).off('resize');
          $contacts.removeAttr('style');
        }
      });
    }

    overlay_Show($contacts);
  });
}

function getContactsCoords($t) {
  return {
    position: 'fixed',
    top: TOPLINE_HEIGHT + 16,
    right: $WINDOW.width() - $t.width() - $t.offset().left,
  };
}
