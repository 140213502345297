import { ACTIVE, CURRENT, OPEN } from '../constants';
import { overlay_Show } from '../overlay';

export function filter_Init() {
  const $section = $('.js-filter-section');
  const $btn = $('.js-filter-btn');
  const $menu = $('.js-filter-menu');
  const $menuBtn = $('.js-filter-menu-btn');

  $btn.on('click', function() {
    const $t = $(this);

    if ($t.hasClass(CURRENT)) {
      $('.js-filter-btn.current').removeClass(CURRENT);
      $('.js-filter-section.open').removeClass(OPEN);
    } else {
      $('.js-filter-btn.current').removeClass(CURRENT);
      $('.js-filter-section.open').removeClass(OPEN);

      $t.addClass(CURRENT);
      $t.closest($section).addClass(OPEN);
    }
  });

  $menuBtn.on('click', function() {
    $('.active', $menu).removeClass(ACTIVE);
    $(this).addClass(ACTIVE);
  });
}

export function mobileFilter_Init() {
  const $btn = $('.js-mobile-filter-btn');
  const $filter = $('.js-mobile-filter');

  $btn.on('click', () => overlay_Show($filter));
}
