export const $BODY = $('body');
export const $WINDOW = $(window);
export const $DOCUMENT = $(document);

export const M_TABLET = 1024;
export const TOPLINE_HEIGHT = 56;

export const EPSILON = 1e-10;
export const SCROLL_DURATION = 200;

export const ACTIVE = 'active';
export const CURRENT = 'current';
export const OPEN = 'open';
export const SHOWN = 'shown';

export const isEscPressed = (event) => event.which === 27;
