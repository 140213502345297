import {
  backFullscreenBtnMarkup, backOverlayBtnMarkup,
  closeFullscreenBtnMarkup,
  leaveFullscreenBtnMarkup, nextFullScreenBtnMarkup, nextOverlayBtnMarkup,
  showFullscreenBtnMarkup,
} from './markups';

import { SHOWN } from '../constants';
import { overlay_Hide, overlay_Show } from '../overlay';

let initiatedGalleries = {};

export function showFullscreen_Init($container) {
  const $btn = $('.js-show-fullscreen-gallery-btn', $container);

  $btn.on('click', function() {
    const $t = $(this);
    const $parent = $t.parent();

    const gallerySelector = `[data-gallery="${$parent.data('gallery')}"].js-fullscreen-gallery`;
    const $gallery = $(gallerySelector);

    if ($gallery.length) {
      overlay_Show();
      fotorama_Init(gallerySelector);
    }
  });
}

function fotorama_Init(gallerySelector) {
  const $parent = $(gallerySelector);

  $parent.addClass(SHOWN);

  const $fotorama = $('.js-fotorama', $parent).fotorama();

  if (initiatedGalleries[gallerySelector]) {
    return false;
  }

  initiatedGalleries = {
    ...initiatedGalleries,
    [gallerySelector]: true,
  };

  const fotorama = $fotorama.data('fotorama');

  $parent
    .append(showFullscreenBtnMarkup)
    .append(backOverlayBtnMarkup)
    .append(nextOverlayBtnMarkup);

  const $fullscreenBtn = $('.js-fullscreen-btn', $parent);
  const $prevBtn = $('.js-overlay-prev', $parent);
  const $nextBtn = $('.js-overlay-next', $parent);

  $fullscreenBtn.on('click', function() {
    fotorama
      .requestFullScreen()
      .setOptions({ nav: 'none' });

    $fotorama
      .append(leaveFullscreenBtnMarkup)
      .append(closeFullscreenBtnMarkup)
      .append(backFullscreenBtnMarkup)
      .append(nextFullScreenBtnMarkup);

    const $leaveFullscreenBtn = $('.js-leave-fullscreen-btn');
    const $closeFullscreenBtn = $('.js-close-fullscreen-btn');
    const $prevFullscreenBtn = $('.js-fullscreen-arrow-prev');
    const $nextFullscreenBtn = $('.js-fullscreen-arrow-next');

    $leaveFullscreenBtn.on('click', function() {
      $leaveFullscreenBtn
        .off('click')
        .remove();

      $closeFullscreenBtn
        .off('click')
        .remove();

      $prevFullscreenBtn
        .off('click')
        .remove();

      $nextFullscreenBtn
        .off('click')
        .remove();

      fotorama
        .cancelFullScreen()
        .setOptions({ nav: 'thumbs' });
    });

    $closeFullscreenBtn.on('click', function() {
      $leaveFullscreenBtn.trigger('click');
      overlay_Hide();
    });

    $prevFullscreenBtn.on('click', function() {
      fotorama.show('<');
    });

    $nextFullscreenBtn.on('click', function() {
      fotorama.show('>');
    });
  });

  $prevBtn.on('click', function() {
    fotorama.show('<');
  });

  $nextBtn.on('click', function() {
    fotorama.show('>');
  });
}
