import {
  topline_Init,
  logo_Init,
  mobileMenu_Init,
  contacts_Init,
} from './common';

import { sliders_Init } from './galleries/commonSliders';
import { mainPageMainGallery_Init } from './galleries/mainPageGallery';
import { newOffersGallery_Init } from './galleries/newOffersGallery';

import { overlay_Init } from './overlay';
import { autocomplete_Init } from './autocomplete';

import { allToys_Init } from './pages/main-page';
import { filter_Init, mobileFilter_Init } from './pages/all-toys';
import { orderForm_Init } from './pages/toy-card';
//import { addReview_Init, auth__Init, after_auth__Init } from './pages/reviews';
import { deliveryAutocomplete_Init, findCity_Init } from './pages/delivery';

$(document).ready(function() {
  // Common
  topline_Init();
  logo_Init();
  mobileMenu_Init();
  contacts_Init();
  overlay_Init();
  autocomplete_Init();

  // Galleries
  mainPageMainGallery_Init();
  newOffersGallery_Init();
  sliders_Init();

  // Main page
  allToys_Init();

  // All toys
  filter_Init();
  mobileFilter_Init();

  // Toy card
  orderForm_Init();

  // Review
  //addReview_Init();
  //auth__Init();
  //after_auth__Init();

  // Delivery
  findCity_Init();
  deliveryAutocomplete_Init();
});
