import { SCROLL_DURATION } from '../constants';

import { scrollToCoordinate } from './scrollToCoordinate';

const scrollToSelector = (selector, duration = SCROLL_DURATION, offset = 0) => {
  const target = document.querySelector(selector);

  if (target) {
    const coord = target.getBoundingClientRect().top + window.pageYOffset - offset;

    scrollToCoordinate(coord, duration);
  }
};

export default scrollToSelector;
