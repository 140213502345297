import { showFullscreen_Init } from './fullScreenGalleries';

import { sliderOptions } from './config';
import { fullScreenBtnMarkup } from './markups';

export function sliders_Init() {
  const $slider = $('.js-slider');

  $slider.each(function() {
    const $t = $(this);
    const $currentContainer = $t.parent();
    const $slide = $('.js-slide', $t);

    $t.owlCarousel(sliderOptions);

    const isFullscreen = $currentContainer.data('fullscreen');

    if (isFullscreen) {
      $currentContainer.append(fullScreenBtnMarkup);
    }

    $slide.click(function() {
      $t.trigger('next.owl.carousel');
    });

    showFullscreen_Init($currentContainer);
  });
}
