import { getArrowContent } from './config'

export const fullScreenBtnMarkup = (`
  <button type="button" class="toy-card__show-fullscreen js-show-fullscreen-gallery-btn">
    <svg class="toy-card__ico-eye">
      <use xlink:href="sprite/sprite.svg#ico_eye"></use>
    </svg>
  </button>
`);

export const showFullscreenBtnMarkup = (`
  <button type="button" class="fullscreen-gallery__fullscreen-btn js-fullscreen-btn">
    <svg class="fullscreen-gallery__ico-expand">
      <use xlink:href="sprite/sprite.svg#ico_expand"></use>
    </svg>
  </button>
`)

export const leaveFullscreenBtnMarkup = (`
  <button type="button" class="fullscreen-gallery__leave-fullscreen-btn js-leave-fullscreen-btn">
    <svg class="fullscreen-gallery__ico-expand">
      <use xlink:href="sprite/sprite.svg#ico_collapse"></use>
    </svg>
  </button>
`)

export const closeFullscreenBtnMarkup = (`
  <button type="button" class="fullscreen-gallery__close-fullscreen-btn js-close-fullscreen-btn">
    <svg class="fullscreen-gallery__ico-cross">
      <use xlink:href="sprite/sprite.svg#ico_cross"></use>
    </svg>
  </button>
`)

export const backFullscreenBtnMarkup = (`
  <button type="button" class="fullscreen-gallery__arrow fullscreen-gallery__arrow--left js-fullscreen-arrow-prev">
    ${getArrowContent()}
  </button>
`)

export const nextFullScreenBtnMarkup = (`
  <button type="button" class="fullscreen-gallery__arrow fullscreen-gallery__arrow--right js-fullscreen-arrow-next">
    ${getArrowContent(null, true)}
  </button>
`)

export const backOverlayBtnMarkup = (`
  <button type="button" class="fullscreen-gallery__arrow fullscreen-gallery__arrow--left js-overlay-prev">
    ${getArrowContent()}
    </button>
`)

export const nextOverlayBtnMarkup = (`
  <button type="button" class="fullscreen-gallery__arrow fullscreen-gallery__arrow--right js-overlay-next">
    ${getArrowContent(null, true)}
  </button>
`)
