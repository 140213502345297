import {overlay_Hide, overlay_Show} from '../overlay';
import {SHOWN} from '../constants';
import Cookies from "js-cookie";

export function orderForm_Init() {
    const $btn = $('.js-show-order-form-btn');
    const $container = $('.js-order-form');
    const $form = $('#order-form');
    const $nameInput = $('.js-name-input');
    const $submitBtn = $('.js-order-form-submit-btn');
    const $successMessage = $('.js-success-sent-message');

    $btn.on('click', function () {
        overlay_Show($container);
        $form.get(0).reset();
        $nameInput.focus();
    });

    $form.on('submit', function (e) {
        e.preventDefault();
        $submitBtn.prop('disabled', true);
        $.post('/assets/snippets/elen/forms.php',
            $form.serialize(),
            function (response) {
                $('.error', $form).removeClass('error');
                $('div.help-block', $form).remove();
                $submitBtn.prop('disabled', false);
                if (response.status) {
                    overlay_Hide($container);
                    overlay_Show($successMessage);
                } else {
                    if (typeof response.errors !== 'undefined' && Object.keys(response.errors).length > 0) {
                        for (var field in response.errors) {
                            var $field = $('[name="' + field + '"]', $form).addClass('error');
                            var errors = response.errors[field];
                            for (var error in errors) {
                                $field.parent().append($('<div class="help-block">' + errors[error] + '</div>'));
                            }
                        }
                        $('.error:first', $form).focus();
                    }
                    if (typeof response.messages !== 'undefined' && response.messages.length > 0) {
                        response.messages.forEach(function (item) {
                            alert(item);
                        });
                    }
                }
            },
            'json'
        ).fail(function () {
            alert('Произошла ошибка!');
            $submitBtn.prop('disabled', false);
        });
    });
}
